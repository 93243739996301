<template>

<app-content :loading="is.loading">

	<app-content-head title="Overview" />

	<app-content-body v-if="!is.loading" class="dashboard">

		<app-dashboard-metric :width="2" label="Active loans" :value="data.active" theme="green" /> 
		<app-dashboard-bars :width="4" :height="2" title="Loans by day" :data="bars.days" />
		<app-dashboard-metric :width="2" label="Total loans" :value="data.total" /> 

		<app-dashboard-metric :width="2" label="Currently overdue" :value="data.late" theme="red" /> 
		<app-dashboard-metric :width="2" label="Average return time" :value="data.average" unit="hr" /> 

	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	data: function() {

		return {
			is: {
				loading: true
			},
			data: {},
			timer: false,
			bars: {
				days: {
					datasets: [
						{ type: 'bar', data: [], yAxis: 'left', backgroundColor: '#3bce5a', tooltip: '{{value}} <small>loan{{value.plural}}</small>' },
						{ type: 'bar', data: [], yAxis: 'left', backgroundColor: '#d35353', tooltip: '{{value}} <small>returned late</small>' },
					],
					axis: {
						x: {
							labels: []
						},
						y: {
							left: {
								type: 'scale',
								min: 10,
								points: 5
							}
						}
					}
				},
				hours: {
					datasets: [
						{ type: 'bar', data: [], yAxis: 'left', backgroundColor: '#3bce5a', tooltip: '{{value}} <small>/small>' },
					],
					axis: {
						x: {
							labels: [
								{label: '0-1'},
								{label: '1-2'},
								{label: '2-3'},
								{label: '3-4'},
								{label: '4+'}
							]
						},
						y: {
							left: {
								type: 'scale',
								min: 10,
								points: 5
							}
						}
					}
				}
			}
		}

	},

	created: function() {

		this.load()

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	methods: {

		load: function() {

			this.$api.get('convention/checkout/overview').then(function(json) {

				Vue.set(this, 'data', json)

				this.bars.days.datasets[0].data = []
				this.bars.days.datasets[1].data = []

				this.$_.each(json.days, function(day) {

					this.bars.days.axis.x.labels.push({
						label: day.text
					})
					
					this.bars.days.datasets[0].data.push(day.total)
					this.bars.days.datasets[1].data.push(day.late)

				}.bind(this))

				this.is.loading = false

				this.timer = this.$_.delay(this.load, 30000)

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.dashboard {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
	grid-auto-rows: min(160px);
}

.is-mobile .dashboard {
	grid-template-columns: 1fr;
}

</style>